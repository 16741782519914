import { lighten } from "../../../utils/sassFunction";

export const colors = [
    { name: "primary", title:"Primario", value: '#59889c'},
    { name: "primaryHover", title:"Primario alternativo", value: '#E1000E'},
    { name: "secondary",title:"Secundario" ,value: '#69b6d8'},
    { name: "clubPrimary",title:"Primario Club" ,value: '#5689c7'},
    { name: "clubSecondary",title:"Secundario Club" , value: '#5689c7'},
    { name: "success", title:"Verde", value: '#97c222'},
    { name: "warning", title:"Amarillo",value: '#eed12c'},
    { name: "danger", title:"Rojo",value: '#ce1d43'},
    { name: "info", title:"Info",value: '#539ea8'},
    { name: "white", title:"Blanco",value: '#ffffff'},
    { name: "light", title:"Claro", value: '#dfdfdf'},
    { name: "grey", title:"Gris",value: '#6f6f6f'},
    { name: "dark", title:"Oscuro",value: '#272727'},
    { name: "black", title:"Negro", value: '#1b1b1b'},
    { name: "bronce", title:"Bronce",value: '#e4d1af'},
    { name: "vibrant", title:"Vivo",value: '#006eff'},
    { name: "clubVibrant", title:"Vivo Club", value: '#87bfe0'},
    { name: "radio", title:"Radio", value: '#1b1b1b'},
];

export const colorsalert = [
    { name: "light", title:"Default", value: `${lighten("#6f6f6f",48)}`}, 
    { name: "success",title:"Suceso", value: '#97c222'},
    { name: "warning", title:"Alerta", value: '#eed12c'},
    { name: "danger",title:"Peligro", value: '#ce1d43'},
];